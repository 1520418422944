import React, { useState, useLayoutEffect, Fragment } from 'react';
import Masonry from 'react-masonry-component';
import { Link } from 'gatsby';

import UIStore from '@stores/UIStore';
import { isMobile } from '@config/mediaQueries';
import { ShopProps } from 'src/templates/Shop';
import {
  GridContainer,
  GridItem,
  GridImageContainer,
  GridImage,
  GridText,
} from './GridComponents';

export const ShopIndex = (props: ShopProps) => {
  const { shopEntries } = props;
  const [opacity, setOpacity] = useState(1);

  useLayoutEffect(() => {
    setOpacity(isMobile() ? 1 : 0); // set opacity to 0 so we don't see the janky masonry
    UIStore.setupPage();
  }, []);

  const handleLayoutComplete = () => {
    setOpacity(1);
  };

  const ContainerEl = isMobile() ? Fragment : Masonry;

  return (
    <div style={{ opacity }}>
      <GridContainer>
        <ContainerEl
          options={{ transitionDuration: 0, gutter: 30 }}
          onLayoutComplete={handleLayoutComplete}
          disableImagesLoaded={false}
        >
          {shopEntries
            .filter(e => !e.hidden)
            .map(entry => {
              return (
                <GridItem>
                  <Link to={`/shop/${entry.slug}`}>
                    <GridImageContainer
                      landscape={entry.indexImageOrientation === 'Landscape'}
                    >
                      <GridImage image={entry.indexImage} />
                    </GridImageContainer>
                    <GridText>{entry.name}</GridText>
                  </Link>
                </GridItem>
              );
            })}
        </ContainerEl>
      </GridContainer>
    </div>
  );
};
