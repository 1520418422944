import React, { Fragment, useEffect } from 'react';

import { Meta } from '@components/Meta';
import { ShopIndex } from '@components/ShopIndex';
import { Footer } from '@components/Footer';
import { Layout } from '@components/Layout';

export type ShopProps = {
  shopEntries: CmsShopEntry[];
} & BasePageProps;

type Props = { pageContext: ShopProps };

export default ({ pageContext: { homepageFonts, shopEntries } }: Props) => (
  <Fragment>
    <Meta title="Shop" />

    <Layout homepageFonts={homepageFonts}>
      <ShopIndex shopEntries={shopEntries} />
    </Layout>
    <Footer />
  </Fragment>
);
